import styled from 'styled-components';
import Button from 'components/Buttons';
import { motion } from 'framer-motion';
import { device } from 'styles/device';

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 50px 0;
  width: 100%;

  div + div {
    border-bottom: 1px solid ${({ theme }) => theme.grey};
  }

  @media ${device.laptop} {
    flex-direction: row;
    margin: 0;
    width: auto;

    div + div {
      margin-left: 41px;
      border-bottom: none;
    }
  }
`;

export const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 315px;
  padding: 0 20px 20px 20px;

  @media ${device.laptop} {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 20px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

// @TODO move to separate file
export const StyledInput = styled.input`
  border: 3px solid black;
  border-radius: 9px;
  color: ${({ theme }) => theme.black};
  margin-bottom: 20px;
  outline: none;
  padding: 14px;
  position: relative;
  transition: 0.2s border-color, 0.2s color;
  /* place for notifications */
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }

  &:focus {
    border-color: ${({ theme }) => theme.green};
  }

  ${({ error, theme }) =>
    error &&
    `
    color:${theme.red};
    border-color: ${theme.red} !important`}
`;

// @TODO move to separate file
export const Notification = styled(motion.div).attrs(() => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}))`
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  ${({ theme, error }) =>
    error &&
    `
  color:${theme.red}`}
`;

export const FooterForm = styled.form`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    width: 411px;

    ${StyledInput} {
      flex: 1;
    }

    ${SubmitButton} {
      width: 106px;
      margin-left: 5px;
      margin-top: 0;
    }
  }
`;

export const FooterSubtitle = styled.p`
  font-size: 23px;
`;

export const FooterLabel = styled.label`
  display: block;
  margin: 13px 0;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const RightSection = styled.div``;

export const Logo = styled.img`
  cursor: pointer;
  width: 91px;

  @media ${device.laptop} {
    width: auto;
    margin-right: 50px;
  }
`;

export const StyledFooter = styled.div`
  margin-top: auto;

  @media ${device.laptop} {
    border-top: 1px solid ${({ theme }) => theme.grey};
  }
`;
