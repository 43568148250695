import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
// components
import SocialLinks from 'components/SocialLinks';
// context
import { useAuth } from 'context/AuthContext';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import { StyledUl, StyledLink, StyledButton, SocialLinksContainer } from './style';
import DropdownProfile from './DropdownProfile';
import DropdownProfileMobile from './DropdownProfileMobile';

const RightNavigation = ({ isOpen, onClick, socialLinks = [] }) => {
  const { pathname, push } = useRouter();
  const { isAuth, logout } = useAuth();
  const queryForTransfer = useTransferQueryParams();

  const buttonText = isAuth ? 'Logout' : 'Login';
  const handleClick = () => (isAuth ? logout() : push('/login'));

  return (
    <>
      <StyledUl isOpen={isOpen}>
        <li>
          <Link scroll={false} href={{ pathname: '/checkout/2', query: queryForTransfer }} passHref>
            <StyledLink
              // isActive={pathname === '/plans'}
              onClick={onClick}
            >
              Menu
            </StyledLink>
          </Link>
        </li>
        <li>
          <Link scroll={false} href={{ pathname: '/help', query: queryForTransfer }} passHref>
            <StyledLink isActive={pathname === '/help'} onClick={onClick}>
              Help &amp; FAQ
            </StyledLink>
          </Link>
        </li>

        {!isAuth && (
          <li className="login-desktop">
            <Link scroll={false} href={{ pathname: '/login', query: queryForTransfer }} passHref>
              <StyledLink isActive={pathname === '/login'} onClick={onClick}>
                Login
              </StyledLink>
            </Link>
          </li>
        )}
        <li id="profile">
          {isAuth ? <DropdownProfile /> : <StyledButton onClick={handleClick}>{buttonText}</StyledButton>}
        </li>
        {isAuth && <DropdownProfileMobile onClick={onClick} />}
        <SocialLinksContainer>
          <SocialLinks socialLinks={socialLinks} secondary />
        </SocialLinksContainer>
      </StyledUl>
    </>
  );
};

RightNavigation.propTypes = {
  socialLinks: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default RightNavigation;
