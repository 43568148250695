import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Icon } from 'components/globals';
import { device } from 'styles/device';

const listVariants = () => ({
  visible: {
    zIndex: 1,
    opacity: 1,
    // height: props.maxHeight,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.025,
    },
  },
  hidden: {
    zIndex: 0,
    opacity: 0,
    // height: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.025,
      staggerDirection: -1,
    },
  },
});

export const StyledDropdown = styled(motion.div)`
  position: relative;
`;

export const List = styled(motion.div).attrs((props) => ({
  initial: 'hidden',
  variants: listVariants(props),
}))`
  list-style: none;
  z-index: 1;
  overflow: hidden;
  right: 0;

  li {
    padding-left: 60px;
  }
`;

export const DropdownHeader = styled(motion.div)`
  cursor: pointer;
  position: relative;
`;

export const DropdownIcon = styled(Icon)`
  left: 0;
  padding: 0 15px;
  position: absolute;
`;

export const DropdownTitle = styled.div``;

export const ArrowIcon = styled(Icon)`
  margin-left: 10px;
  transform: ${({ open }) => open && `scale(1, -1)`};

  transition: transform 0.6s ease;
`;

export const StyledDropdownProfileMobile = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.black};
  display: flex;
  font-size: 20px;
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.yellow};
  }

  @media ${device.tablet} {
    &::after {
      display: block;
      color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};
      content: '';
      position: absolute;
      opacity: ${({ isActive }) => (isActive ? 1 : 0)};
      background-color: ${({ theme }) => theme.white};
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px) rotate(-1deg);
      height: 3px;
      width: ${({ isActive }) => (isActive ? '120%' : 0)};
      transition: 0.25s;
    }

    &:hover {
      color: ${({ theme }) => theme.black};

      &::after {
        opacity: 1;
        width: 120%;
      }
    }
  }
`;
