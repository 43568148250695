import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
// hooks
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import Meta from 'components/Meta';
// import useExitIntent from 'utils/useExitIntent';
import Header from './Header';
import Footer from './Footer';
// import SurveyPopup from './SurveyPopup';

const Layout = ({ children, seo = {}, allowGoogleBot, ...rest }) => {
  const { header_logo: headerLogo, header_link: headerLink, ...restProps } = rest?.data || {};

  // survey popup
  // const [showPopup] = useExitIntent();

  // Trigger Google Analytics on the page changing
  useGoogleAnalytics();

  // Hotjar Analytics
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(2063767, 6);
    }
  }, []);

  // seo
  const openGraph = seo.social?.find((item) => item.slice_type === 'open_graph_general_card')?.primary;
  const twitter = seo.social?.find((item) => item.slice_type === 'twitter_card')?.primary;

  const seoConfig = {
    title: seo.meta_title,
    description: seo.meta_description,
    openGraph,
    twitter,
  };

  return (
    <>
      <Meta {...seoConfig} allowGoogleBot={allowGoogleBot} />
      <Header headerLogoUrl={headerLogo?.url} headerLinkUrl={headerLink?.url} {...restProps} />

      <main style={{ display: 'flex', flex: 1, minHeight: '500px' }}>{children}</main>

      {/* <SurveyPopup show={showPopup} /> */}
      <Footer {...restProps} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  seo: PropTypes.object,
  allowGoogleBot: PropTypes.bool,
};

export default Layout;
