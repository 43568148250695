import PropTypes from 'prop-types';
import { Icon } from 'components/globals';
import { ButtonLink } from 'components/Buttons/ButtonLink';
import { StyledSocialLinks } from './style';

const SocialLinks = ({ socialLinks = [], secondary }) => {
  return (
    <StyledSocialLinks>
      {socialLinks.map(({ icon, link }, index) => (
        <ButtonLink secondary={secondary} rounded key={index} href={link.url} target="_blank" rel="noreferrer">
          <Icon src={icon.url} />
        </ButtonLink>
      ))}
    </StyledSocialLinks>
  );
};

SocialLinks.propTypes = {
  socialLinks: PropTypes.array,
  secondary: PropTypes.bool,
};

export default SocialLinks;
