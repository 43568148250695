import styled from 'styled-components';
import { Icon } from 'components/globals';
import { device } from 'styles/device';

export const StyledSocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  ${Icon} {
    cursor: pointer;
  }

  a + a {
    margin-left: 22px;
  }

  a:first-child > ${Icon} {
    width: 15px;
  }

  a:nth-child(2) > ${Icon}:last-child {
    width: 20px;
  }

  a:last-child > ${Icon}:last-child {
    width: 20px;
  }

  @media ${device.laptop} {
    justify-content: flex-start;
  }
`;
