import { useEffect } from 'react';

const disableDocumentScrolling = () => {
  // Get the body styles
  const bodyStyles = window.getComputedStyle(document.getElementsByTagName('body')[0]);

  if (bodyStyles.position !== 'fixed') {
    // Get the top vertical offset.
    const topVerticalOffset =
      // eslint-disable-next-line no-nested-ternary
      typeof window.pageYOffset !== 'undefined'
        ? window.pageYOffset
        : document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : 0;

    // Set to the document height 100vh + offset to prevent white space at the bottom of screen
    document.documentElement.style.height = `calc(100vh + ${topVerticalOffset}px)`;

    // Set the body to fixed position (this is the only way around IOS' overscroll "feature").
    document.body.style.position = 'fixed';

    // Set back the offset position by user negative margin on the fixed document.
    document.documentElement.style.marginTop = `-${topVerticalOffset}px`;
  }
};

const enableDocumentScrolling = () => {
  // Get the body styles
  const bodyStyles = window.getComputedStyle(document.getElementsByTagName('body')[0]);

  if (bodyStyles.position === 'fixed') {
    // Revert the 100% height on the document.
    document.documentElement.style.height = '100%';
    // Remove the fixed position on the document.
    document.body.style.position = null;

    // Calculate back the original position of the non-fixed document.
    const scrollPosition = -1 * parseFloat(document.documentElement.style.marginTop);
    // Remove fixed document negative margin.
    document.documentElement.style.marginTop = null;
    // Scroll to the original position of the non-fixed document.
    window.scrollTo(0, scrollPosition);
  }
};

const useLockBodyScroll = (open) => {
  // use effect for prevent scrolling while menu is open
  useEffect(() => {
    if (open) {
      disableDocumentScrolling();
      return;
    }

    enableDocumentScrolling();

    return () => enableDocumentScrolling();
  }, [open]);
};

export default useLockBodyScroll;
