import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Icon } from 'components/globals';
import { device } from 'styles/device';

const listVariants = {
  visible: {
    zIndex: 1,
    opacity: 1,
    // height: props.maxHeight,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.025,
    },
  },
  hidden: {
    zIndex: 0,
    opacity: 0,
    // height: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.025,
      staggerDirection: -1,
    },
  },
};

const itemVariants = {
  visible: { scale: 1, opacity: 1 },
  hidden: { scale: 0, opacity: 0 },
};

export const StyledDropdown = styled(motion.div)`
  position: relative;
  /* width: 100%; */

  @media ${device.tablet} {
    /* width: 141px; */
  }
`;

export const List = styled(motion.div).attrs(() => ({
  // initial: 'hidden',
  variants: listVariants,
}))`
  position: absolute;
  list-style: none;
  margin: 0;
  background-color: ${({ theme }) => theme.white};
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 30px;
  border: 1px solid #dedede;
  width: 214px;
  right: 0;

  ${({ isOpen }) =>
    !isOpen &&
    `
     pointer-events: none`}
`;

export const ListItem = styled(motion.div).attrs(() => ({
  variants: itemVariants,
}))`
  display: flex;
  justify-content: center;
  justify-content: flex-start;

  /* background-color: ${({ isSelected, theme }) => (isSelected ? theme.lightGreen : 'white')}; */
  /* font-size:20px; */

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.orange};
    }
  }

  button {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: transparent;
  }

  @media ${device.tablet} {
    button {
      padding: 10px 0px;

      &:hover {
        color: ${({ theme }) => theme.orange};
      }
    }
  }
`;

export const DropdownHeader = styled(motion.div).attrs(() => ({
  whileTap: {
    scale: 0.9,
  },
  whileHover: {
    scale: 0.95,
  },
}))`
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  outline: none;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  /* border: 2px solid ${({ theme }) => theme.lightGreen}; */

  @media (hover: hover) {
    &:hover {
      /* background-color: ${({ theme }) => theme.lightGreen}; */
    }
  }

  @media ${device.tablet} {
    padding: 4px;
  }
`;

export const DropdownIcon = styled(Icon)`
  left: 0;
  padding: 0 15px;
  position: absolute;

  @media ${device.tablet} {
    display: none;
  }
`;

export const DropdownTitle = styled.div``;

export const ArrowIcon = styled(Icon)`
  transform: ${({ open }) => open && `scale(1, -1)`};
  transition: transform 0.6s ease;
  /* margin-left: 10px; */
`;

export const ListButton = styled(motion.button).attrs(() => ({
  initial: 'hidden',
  variants: itemVariants,
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
}))`
  outline: none;
  border: none;
  border-radius: 8px;
  /* border: 2px solid ${({ theme }) => theme.lightGreen}; */
  border: 1px solid #dedede;

  width: 100%;
  background-color: transparent;
  padding: 5px 0;
  margin: 20px 13px 20px 13px;
  width: -webkit-fill-available;
  /* firefox */
  width: -moz-available;

  &:hover {
    background-color: ${({ theme }) => theme.lightGreen};
  }
`;

export const DropdownProfile = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  margin: 23px 0 23px 0;
`;

export const ProfileButton = styled(motion.button).attrs(() => ({
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
}))`
  display: none;
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.orange};

  justify-content: center;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  transition: 0.2s background-color;

  @media (hover: hover) {
    &:hover {
      /* background-color: ${({ theme }) => theme.lightGreen}; */
    }
  }

  @media ${device.tablet} {
    display: block;
  }
`;
