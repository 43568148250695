import React, { useState, useRef } from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';
import onClickOutside from 'react-onclickoutside';
// context
import { useAuth } from 'context/AuthContext';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import arrow from 'assets/icons/arrow.svg';
import {
  StyledDropdown,
  DropdownHeader,
  DropdownTitle,
  ArrowIcon,
  ProfileButton,
  DropdownProfile,
  List,
  ListItem,
} from './style';

const links = [
  // { path: '/profile/subscriptions', name: 'Subscriptions' },
  { path: '/profile/my-menu', name: 'My menu' },
  // { path: '/profile/account', name: 'Account' },
  { path: '/profile/settings', name: 'Settings' },
  { path: '/profile/shipments', name: 'Shipments' },
  { path: '/contact', name: 'Help' },
  { path: '/profile/referral', name: 'Refer & Earn' },
];

function DropdownMenu() {
  const { logout, customer } = useAuth();
  const queryForTransfer = useTransferQueryParams();

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const content = useRef(null);
  DropdownMenu.handleClickOutside = () => setOpen(false);

  // create customer initials from the full name;
  const customerInitials = customer.name
    ?.split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <DropdownProfile>
      <ProfileButton onClick={toggle}>{customerInitials}</ProfileButton>
      <StyledDropdown>
        <DropdownHeader onClick={toggle} open={open}>
          <DropdownTitle>
            <ArrowIcon src={arrow} open={open} />
          </DropdownTitle>
        </DropdownHeader>
        <List
          ref={content}
          maxHeight={open ? `${content.current.scrollHeight + 19}px` : '0px'}
          initial="hidden"
          animate={open ? 'visible' : 'hidden'}
          isOpen={open}
        >
          {links.map((item, index) => (
            <ListItem key={index}>
              <Link scroll={false} href={{ pathname: item.path, query: queryForTransfer }} passHref>
                <motion.button
                  type="button"
                  whileHover={{
                    scale: 0.95,
                  }}
                  whileTap={{
                    scale: 0.9,
                  }}
                >
                  <span>{item.name}</span>
                </motion.button>
              </Link>
            </ListItem>
          ))}

          <ListItem>
            <motion.button
              type="button"
              onClick={logout}
              whileHover={{
                scale: 0.95,
              }}
              whileTap={{
                scale: 0.9,
              }}
            >
              <span>Log out</span>
            </motion.button>
          </ListItem>
        </List>
      </StyledDropdown>
    </DropdownProfile>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => DropdownMenu.handleClickOutside,
};

export default onClickOutside(DropdownMenu, clickOutsideConfig);
