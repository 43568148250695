import PropTypes from 'prop-types';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import RightNavigation from './RightNavigation';
import { StyledBurger } from './style';

const Burger = ({ headerLinkUrl, socialLinks, isOpen, setIsOpen }) => {
  useLockBodyScroll(isOpen);

  return (
    <>
      <StyledBurger open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNavigation
        headerLinkUrl={headerLinkUrl}
        socialLinks={socialLinks}
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
    </>
  );
};

Burger.propTypes = {
  headerLinkUrl: PropTypes.string,
  socialLinks: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default Burger;
