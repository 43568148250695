import { useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// components
import PromoBar from './PromoBar';
import Burger from './Burger';
// styles
import { StyledHeader, Logo, Container, Nav } from './style';

const Header = ({ headerLogoUrl, headerLinkUrl, social_links: socialLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const queryForTransfer = useTransferQueryParams();

  return (
    <StyledHeader>
      <PromoBar isOpen={isOpen} />

      <Container>
        <Nav>
          <Link scroll={false} href={{ pathname: '/', query: queryForTransfer }}>
            <Logo src={headerLogoUrl} alt="chefly" />
          </Link>

          <Burger headerLinkUrl={headerLinkUrl} socialLinks={socialLinks} isOpen={isOpen} setIsOpen={setIsOpen} />
        </Nav>
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  social_links: PropTypes.array,
  headerLogoUrl: PropTypes.string,
  headerLinkUrl: PropTypes.string,
};

export default Header;
