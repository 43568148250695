import React, { useState, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
// context
import { useAuth } from 'context/AuthContext';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import arrow from 'assets/icons/arrow.svg';
import { DropdownHeader, DropdownTitle, ArrowIcon, StyledDropdownProfileMobile, List, StyledLink } from './style';

const links = [
  // { path: '/profile/subscriptions', name: 'Subscriptions' },
  { path: '/profile/my-menu', name: 'My menu' },
  // { path: '/profile/account', name: 'Account' },
  { path: '/profile/settings', name: 'Settings' },
  { path: '/profile/shipments', name: 'Shipments' },
  { path: '/contact', name: 'Help' },
  { path: '/profile/referral', name: 'Refer & Earn' },
];

function DropdownProfileMobile({ onClick }) {
  const { logout } = useAuth();

  const queryForTransfer = useTransferQueryParams();

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const content = useRef(null);
  DropdownProfileMobile.handleClickOutside = () => setOpen(false);

  return (
    <StyledDropdownProfileMobile>
      <li>
        <DropdownHeader onClick={toggle} open={open}>
          <DropdownTitle>
            <StyledLink>
              My Profile
              <ArrowIcon src={arrow} open={open} />
            </StyledLink>
          </DropdownTitle>
        </DropdownHeader>
      </li>
      <List
        ref={content}
        maxHeight={open ? `${content.current.scrollHeight + 19}px` : '0px'}
        animate={open ? 'visible' : 'hidden'}
      >
        {links.map((item, index) => (
          <li key={index}>
            <Link scroll={false} href={{ pathname: item.path, query: queryForTransfer }} passHref>
              <StyledLink onClick={onClick}>{item.name}</StyledLink>
            </Link>
          </li>
        ))}
        <li>
          <StyledLink onClick={logout}>Log out</StyledLink>
        </li>
      </List>
    </StyledDropdownProfileMobile>
  );
}

DropdownProfileMobile.propTypes = {
  onClick: PropTypes.func,
};

const clickOutsideConfig = {
  handleClickOutside: () => DropdownProfileMobile.handleClickOutside,
};

export default onClickOutside(DropdownProfileMobile, clickOutsideConfig);
