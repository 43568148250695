import styled from 'styled-components';
import { motion } from 'framer-motion';
import { device } from 'styles/device';

export const ButtonLink = styled(motion.a).attrs(() => ({
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ secondary, theme }) => (secondary ? theme.white : theme.yellow)};
  color: ${({ theme }) => theme.black};
  height: 35px;
  width: 213px;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.yellow};
  transition: 0.2s background-color;
  user-select: none;

  &:active {
    background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
  }

  ${({ rounded }) =>
    rounded &&
    `
    height: 40px;
    width: 40px;
    border-radius:50%;
  `}

  @media (hover: hover) {
    &:hover {
      background-color: ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
      border: 2px solid ${({ secondary, theme }) => (secondary ? theme.yellow : theme.orange)};
    }
  }

  @media ${device.laptop} {
    height: 50px;
    width: 232px;
    font-size: 18px;
    border: 2px solid ${({ theme }) => theme.yellow};

    ${({ rounded }) =>
      rounded &&
      `
    height: 40px;
    width: 40px;
    border-radius:50%;
  `}
  }
`;
