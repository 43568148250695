import styled from 'styled-components';
import { Icon } from 'components/globals';
import { Text } from 'components/globals/TypographyElements';
import { device } from 'styles/device';

export const LinksButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: #444;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 19px 26px 19px 12px;
  position: relative;
  transition: background-color 0.3s ease;

  @media ${device.laptop} {
    cursor: initial;
    padding: 0 10px 10px 10px;
  }
`;

export const LinksSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grey};
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    border-top: none;
  }
`;

export const LinksTitle = styled(Text)`
  font-family: 'paralucent-medium', sans-serif;
  text-align: left;
`;

export const LinksContent = styled.div`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  transition: max-height 0.6s ease;

  @media ${device.laptop} {
    max-height: 100%;
    margin-top: 22px;
  }
`;

export const LinkText = styled.a`
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  display: block;
  font-family: 'paralucent-light', sans-serif;
  font-size: 16px;
  margin: 0 12px 5px 12px;
  position: relative;
  text-decoration: none;
  width: fit-content;

  @media ${device.laptop} {
    font-size: 18px;
    margin: 0px 0px 6px 10px;

    &:hover {
      &::after {
        content: '';
        position: absolute;
        background-color: ${({ theme }) => theme.black};
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0px) rotate(-1deg);
        height: 3px;
        width: 120%;
      }
    }

    @media ${device.laptop} {
      &::after {
        display: block;
        color: ${({ theme }) => theme.black};
        content: '';
        position: absolute;
        opacity: 0;
        background-color: ${({ theme }) => theme.white};
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0px) rotate(-1deg);
        height: 3px;
        width: 0;
        transition: 0.25s;
      }

      &:hover {
        color: ${({ theme }) => theme.black};

        &::after {
          opacity: 1;
          width: 120%;
        }
      }
    }
  }
`;

export const LinksIcon = styled(Icon)`
  align-self: center;
  transform: ${({ isActive }) => isActive && `scale(1, -1)`};
  transition: transform 0.6s ease;

  @media ${device.laptop} {
    display: none;
  }
`;
