import styled from 'styled-components';
import { device } from 'styles/device';
import { motion } from 'framer-motion';

export const StyledHeader = styled.header`
  /* background-color: ${({ theme }) => theme.yellow}; */
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 9px 3px rgba(41,41,41,.25);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1220px;
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 27px;
  transition: 0.2s all;
  width: 68px;
  z-index: 4;

  @media ${device.tablet} {
    width: 126px;
    height: 49px;
    margin-left: 20px;
  }
`;

export const Nav = styled.nav`
  align-items: center;
  display: flex;
  height: 59px;
  justify-content: space-between;
  padding: 0 27px;

  @media ${device.tablet} {
    height: 65px;
    padding: 0;
  }
`;

export const StyledUl = styled.ul`
  /* background-color: ${({ theme }) => theme.yellow}; */
  background-color: ${({ theme }) => theme.white};
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin-top: 59px;
  overflow: auto;
  padding: 0 0 30px 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 3;

  li {
    color: ${({ theme }) => theme.black};
    cursor: pointer;
    padding: 13px 32px;

    &:hover {
      background-color: ${({ theme }) => theme.white};
    }
  }

  #profile {
    display: none;
  }

  @media ${device.tablet} {
    margin: 0;
    padding: 0;
    position: static;
    height: auto;
    width: auto;
    flex-flow: row nowrap;
    padding-top: 0;
    background-color: inherit;
    transform: none;
    overflow: initial;

    li {
      display: flex;
      align-items: center;
      padding: 0 13px;

      &:nth-child(3) {
        padding-right: 36px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    #profile {
      display: block;
    }

    .login-desktop {
      display: none;
    }
  }
`;

export const StyledLink = styled(motion.a).attrs(() => ({
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
}))`
  color: ${({ theme }) => theme.black};
  display: block;
  font-size: 20px;
  padding-bottom: 1px;
  position: relative;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.yellow};
  }

  @media ${device.tablet} {
    &::after {
      display: block;
      color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};
      content: '';
      position: absolute;
      opacity: ${({ isActive }) => (isActive ? 1 : 0)};
      background-color: ${({ theme }) => theme.white};
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0px) rotate(-1deg);
      height: 3px;
      width: ${({ isActive }) => (isActive ? '120%' : 0)};
      transition: 0.25s;
    }

    &:hover {
      color: ${({ theme }) => theme.black};

      &::after {
        opacity: 1;
        width: 120%;
      }
    }
  }
`;

export const StyledButton = styled(motion.a).attrs(() => ({
  whileHover: {
    scale: 0.95,
    // borderRadius: '100%',
  },
  whileTap: {
    scale: 0.9,
    // borderRadius: '100%',
  },
}))`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  outline: none;
  display: block;

  @media ${device.tablet} {
    font-size: 20px;
    background-color: ${({ theme }) => theme.white};
    padding: 9px 29px;
    border-radius: 8px;

    &:active {
      background-color: ${({ theme }) => theme.lightYellow};
    }
  }
`;

export const StyledBurger = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 2rem;
  justify-content: space-around;
  width: 2rem;
  z-index: 20;

  @media ${device.tablet} {
    display: none;
  }

  div {
    background-color: ${({ theme }) => theme.black};
    height: 4px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    width: 32px;

    &:nth-child(1) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ isOpen }) => (isOpen ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const SocialLinksContainer = styled.div`
  margin-top: auto;

  @media ${device.tablet} {
    display: none;
  }
`;
