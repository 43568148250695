import PropTypes from 'prop-types';
import Head from 'next/head';
import SEO from 'next-seo.config';

const Meta = ({ openGraph, twitter, title, description, allowGoogleBot }) => {
  return (
    <Head>
      {/* general */}
      <title key="title">{title}</title>

      {/* Favicon options */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffb432" />
      <meta name="msapplication-TileColor" content="#2d89ef" />
      <meta name="theme-color" content="#ffffff" />
      <link rel="canonical" href="https://www.eatchefly.com" />

      {/* FACEBOOK verification */}
      <meta name="facebook-domain-verification" content="1erxgwa96z2plg91qcref0oumeisvr" />

      {/* SEO general */}
      <meta name="description" content={description} />
      <meta name="robots" content="index,follow" />
      {allowGoogleBot && <meta name="googlebot" content="index,follow" />}
      {/* <meta name="googlebot" content="index,follow" /> */}

      <meta name="keywords" content="" />
      <meta property="Author" content="" />
      <meta property="Copyright" content="" />
      <meta property="Address" content="" />

      {/* Open Graph data */}
      <meta property="og:site_name" content={openGraph.siteName} />
      <meta property="og:title" content={openGraph.title} />
      <meta property="og:description" content={openGraph.description} />
      <meta property="og:url" content={openGraph.url} />
      <meta property="og:type" content={openGraph.type} />
      <meta property="og:image" content={openGraph.image?.url} />
      {/* <meta property="og:image:width" content={openGraph.image.dimensions.width} />
    <meta property="og:image:height" content={openGraph.image.dimensions.height} /> */}

      {/* Twitter Card data */}
      <meta name="twitter:title" content={twitter.title} />
      <meta name="twitter:image" content={twitter.image?.url} />
      <meta name="twitter:url" content={twitter.url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={openGraph.description} />
    </Head>
  );
};

Meta.defaultProps = {
  ...SEO,
  allowGoogleBot: true,
};

Meta.propTypes = {
  openGraph: PropTypes.object,
  twitter: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  allowGoogleBot: PropTypes.bool,
};

export default Meta;
