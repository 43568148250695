import { useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
// components
import { Container } from 'components/globals';
import SocialLinks from 'components/SocialLinks';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// utils
import { logKlaviyoEvent } from 'utils/analytics';
import Links from './Links';
// styles
import {
  StyledFooter,
  LeftSection,
  Logo,
  RightSection,
  FooterSubtitle,
  FooterLabel,
  FooterForm,
  Content,
  LinksContainer,
  SubmitButton,
  StyledInput,
  Notification,
} from './style';

const Footer = ({
  footer_logo: footerLogo,
  company_links: companyLinks,
  learn_more_links: learnMoreLinks,
  social_links: socialLinks,
}) => {
  const [email, setEmail] = useState('');
  const [state, setState] = useState('IDLE');
  const [errorMessage, setErrorMessage] = useState(null);

  const queryForTransfer = useTransferQueryParams();

  const subscribe = async (event) => {
    event.preventDefault();
    setState('LOADING');
    setErrorMessage(null);
    try {
      logKlaviyoEvent('identify', { $email: email });
      setEmail('');
      setState('SUCCESS');
    } catch (e) {
      setErrorMessage(e.response.data.error);
      setState('ERROR');
    }
  };

  const handleChange = (event) => {
    if (state === 'ERROR') {
      setState('IDLE');
    }

    setEmail(event.target.value);
  };

  return (
    <StyledFooter>
      <Container>
        <Content>
          <LeftSection>
            <Link href={{ pathname: '/', query: queryForTransfer }}>
              <Logo src={footerLogo?.url} size={91} alt="" />
            </Link>
            <LinksContainer>
              <Links title="Company" links={companyLinks} />
              <Links title="Learn more" links={learnMoreLinks} />
            </LinksContainer>
          </LeftSection>
          <RightSection>
            <FooterSubtitle>Let&apos;s keep in touch</FooterSubtitle>
            <FooterLabel htmlFor="email">Sign up to our newsletters</FooterLabel>
            <FooterForm onSubmit={subscribe}>
              <div style={{ position: 'relative', flex: 1 }}>
                <StyledInput
                  value={email}
                  onChange={handleChange}
                  type="email"
                  id="email"
                  placeholder="Enter email address"
                  error={state === 'ERROR'}
                />
                {state === 'ERROR' && <Notification error>{errorMessage}</Notification>}
                {state === 'SUCCESS' && <Notification>Thank you for signing up!</Notification>}
              </div>

              <SubmitButton type="submit" disabled={state === 'LOADING'}>
                Submit
              </SubmitButton>
            </FooterForm>
            <SocialLinks socialLinks={socialLinks} />
          </RightSection>
        </Content>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  footer_logo: PropTypes.object,
  company_links: PropTypes.array,
  learn_more_links: PropTypes.array,
  social_links: PropTypes.array,
};

export default Footer;
