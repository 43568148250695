export default {
  title: 'CHEFLY - Healthy, Convenient Weekly Meals',
  description:
    'Choose from 29+ chef-cooked, protein packed meals. Enjoy at home, at work or on the move - just heat when you’re ready to eat',
  openGraph: {
    title: 'CHEFLY - Healthy, Convenient Weekly Meals',
    description:
      'Choose from 29+ chef-cooked, protein packed meals. Enjoy at home, at work or on the move - just heat when you’re ready to eat',
    siteName: 'CHEFLY',
    url: 'https://www.eatchefly.com',
    type: 'website',
    image: {
      url:
        'https://images.prismic.io/chefly/732109d0-04da-4660-a1d1-13e47acbf657_chefly_healthy_weekly_meal_delivery.jpeg?auto=compress,format',
    },
    width: '1500',
    height: '1000',
  },
  twitter: {
    title: 'CHEFLY - Healthy, Convenient Weekly Meals',
    description:
      'Choose from 29+ chef-cooked, protein packed meals. Enjoy at home, at work or on the move - just heat when you’re ready to eat',
    siteName: 'CHEFLY',
    url: 'https://www.eatchefly.com',
    type: 'website',
    image: {
      url:
        'https://images.prismic.io/chefly/732109d0-04da-4660-a1d1-13e47acbf657_chefly_healthy_weekly_meal_delivery.jpeg?auto=compress,format',
    },
    width: '1500',
    height: '1000',
  },
};
