import { useRouter } from 'next/router';

const useTransferQueryParams = (
  paramsToTransfer = ['utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'coupon']
) => {
  const { query } = useRouter();

  const filteredQueryParams = Object.keys(query).reduce((acc, current) => {
    if (paramsToTransfer.includes(current)) {
      return { ...acc, [current]: query[current] };
    }
    return { ...acc };
  }, {});

  return filteredQueryParams;
};

export default useTransferQueryParams;
