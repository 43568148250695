import { useState, useRef } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
// hooks
import useTransferQueryParams from 'hooks/useTransferQueryParams';
// styles
import arrow from 'assets/icons/arrow.svg';
import { LinksSection, LinksButton, LinksTitle, LinkText, LinksContent, LinksIcon } from './style';

const Links = ({ title, links = [] }) => {
  const [isActive, setIsActive] = useState(false);

  const content = useRef(null);

  const queryForTransfer = useTransferQueryParams();

  const toggleLinks = () => setIsActive((prevState) => !prevState);

  return (
    <LinksSection>
      <LinksButton onClick={toggleLinks}>
        <LinksTitle>{title}</LinksTitle>
        <LinksIcon isActive={isActive} src={arrow} size={15} sizeLg={30} />
      </LinksButton>
      <LinksContent ref={content} maxHeight={isActive ? `${content.current.scrollHeight}px` : '0px'}>
        {links?.map(({ link_label: linkLabel, link_url: linkUrl }, index) => (
          <Link key={index} scroll={false} href={{ pathname: linkUrl, query: queryForTransfer }} passHref>
            <LinkText>{linkLabel}</LinkText>
          </Link>
        ))}
      </LinksContent>
    </LinksSection>
  );
};

Links.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array,
};

export default Links;
