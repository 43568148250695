import PropTypes from 'prop-types';
import { parseCookies } from 'nookies';
import styled from 'styled-components';
import { usePromoBar } from 'context/PromoBarContext';
import { device } from 'styles/device';
import SVGIcon from 'components/SVGIcon';

const StyledBar = styled.div`
  background-color: ${(p) => p.theme.yellow};
  color: ${(p) => p.theme.black};
  display: flex;
  display: flex;
  flex-direction: row;
  font-family: 'paralucent-light', sans-serif;
  justify-content: center;
  padding: 20px 10px;
  width: 100%;
    ${(p) =>
      p.coupon === 'SAVE30' &&
      `
   span {
     font-weight: bold;
   }
  `}

    @media ${device.tabletS} {
    font-size: 20px;

    span {
      display: inline;
    }
  }
`;

const Content = styled.div`
  line-height: 24px;
  margin: 0 auto;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const couponsText = {
  SAVE30: (
    <Content>
      <span role="img" aria-label="ok">
        ✅ &nbsp; Discount applied to checkout &nbsp;
      </span>
      {/* £30 off your first 3 deliveries */}
    </Content>
  ),
  default: (
    <Content>
      Your promo has been applied &nbsp;&nbsp;
      <span role="img" aria-label="hands">
        🙌
      </span>
    </Content>
  ),
};

const PromoBar = ({ isOpen }) => {
  const { isVisible, closePromoBar } = usePromoBar();
  const { coupon } = parseCookies();

  if (!isVisible || isOpen) return <></>;

  return (
    // temporary solution for hide bar for profile page
    <StyledBar coupon={coupon}>
      {couponsText[coupon] || couponsText.default}

      <CloseButton onClick={closePromoBar}>
        <SVGIcon name="close" width={14} fill="black" />
      </CloseButton>
    </StyledBar>
  );
};

PromoBar.propTypes = {
  isOpen: PropTypes.bool,
};

export default PromoBar;
